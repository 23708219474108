/*------------------------------------*\
							Animations
\*------------------------------------*/

.burger {
	.burger__icon--top,
	.burger__icon--mid,
	.burger__icon--bot {
		transform: rotate(180deg);
		transition: all .6s ease-in-out;
	}

	&.is-open {

		.burger__icon--top {
			transform: translateY(calc(.6em - 1px)) rotate(45deg);
		}

		.burger__icon--mid {
			opacity: 0;
			transform: scale(0, 1);
		}

		.burger__icon--bot {
			transform: translateY(calc(-.6em - 1px)) rotate(-45deg);
		}
	}
}
