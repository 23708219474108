@import '../base/variables';
@import '../../../../../../../node_modules/bootstrap/scss/mixins/breakpoints';

/*------------------------------------*\
		Modules
\*------------------------------------*/

.mod-product-categories, .mod-products {
  padding-top: 30px;
  padding-bottom: 45px;

  h2 {
    text-align: center;

    @include media-breakpoint-down(md) {
      padding: 0 10px;
    }
  }

  .owl-carousel {
    max-width: 1455px;
    margin: 0 auto;

    @media (max-width: 1550px) {
      max-width: 1160px;

      .owl-nav {
        display: block !important;
        position: absolute;
        top: 160px;
        width: 100%;

        .owl-prev {
          left: -50px;
          right: auto;
        }

        .owl-next {
          right: -50px;
          left: auto;
        }
      }
    }

    @include media-breakpoint-down(lg) {
      max-width: 861px;

      .owl-nav {
        .owl-prev {
          left: -45px;
          right: auto;
        }

        .owl-next {
          right: -45px;
          left: auto;
        }
      }
    }

    @include media-breakpoint-down(sm) {
      max-width: calc(100% - 100px);

      .owl-nav {
        .owl-prev {
          left: -40px;
          right: auto;
        }

        .owl-next {
          right: -40px;
          left: auto;
        }
      }
    }

    .owl-nav {
      .owl-prev, .owl-next {
        position: absolute;

        img {
          width: 25px;
        }

        &.disabled {
          display: none;
        }
      }
    }
  }

  .mod-product-category, .mod-product {
    display: block;
    width: 267px;
    text-decoration: none;

    .image-wrapper {
      width: 100%;
      height: 267px;

      img {
        @include cover-image;
      }
    }

    .content {
      padding: 15px 20px;
      border: 1px solid #E5E5E5;
      border-top: none;
      background: #fff;

      @include media-breakpoint-down(sm) {
        padding: 10px 10px;
      }

      .title {
        color: $black;
        font-size: 20px;
        font-weight: bold;
        line-height: 27px;
        text-transform: uppercase;
        max-height: 54px;
        overflow: hidden;

        @include media-breakpoint-down(sm) {
          font-size: 18px;
          line-height: 24px;
          max-height: 48px;
        }
      }

      .description {
        height: 36px;
        max-height: 36px;
        margin-bottom: 5px;
        color: $grey-text;
        font-size: 14px;
        line-height: 18px;
        overflow-y: hidden;

        @include media-breakpoint-down(sm) {
          height: 45px;
          max-height: 45px;
          font-size: 12px;
          line-height: 15px;
        }
      }

      .discover {
        display: inline-block;
        font-size: 14px;
        font-weight: 500;
        text-transform: uppercase;
        text-decoration: none;

        @include media-breakpoint-down(sm) {
          font-size: 10px;
        }

        span {
          vertical-align: middle;
        }

        svg {
          height: 8.43px;
          width: 8.43px;
          margin-left: 6px;

          @include media-breakpoint-down(sm) {
            height: 5.42px;
            width: 5.42px;
          }
        }
      }
    }
  }

  .mod-product-category {
    border-top: 4px solid;

    &:first-child {
      border-color: $taupe;
    }

    &:nth-child(2) {
      border-color: $primary;
    }

    &:nth-child(3) {
      border-color: $purple;
    }

    &:nth-child(4) {
      border-color: $yellow;
    }

    &:nth-child(5) {
      border-color: $orange;
    }
  }


  .owl-item, .col {
    &:first-child .mod-product-category {
      border-color: $taupe;
    }

    &:nth-child(2) .mod-product-category {
      border-color: $primary;
    }

    &:nth-child(3) .mod-product-category {
      border-color: $purple;
    }

    &:nth-child(4) .mod-product-category {
      border-color: $yellow;
    }

    &:nth-child(5) .mod-product-category {
      border-color: $orange;
    }
  }

  .mod-product-categories-wrapper, .mod-products-wrapper {
    .mod-product-category, .mod-product {
      width: 100%;
      margin-bottom: 30px;
    }
  }

  .mod-product-categories-wrapper {
    .mod-product-category {
      @include media-breakpoint-down(sm) {
        text-align: center;
      }
    }
  }

  .mod-products-wrapper {
    .mod-product {
      @include media-breakpoint-down(sm) {
        .image-wrapper {
          height: 140px;
        }
      }
    }
  }
}

.mod-slider {
  position: relative;
  height: 620px;
  background: #F6F6F6;

  @include media-breakpoint-down(md) {
    height: 400px;
  }

  .slide {
    position: relative;
    height: 620px;

    @include media-breakpoint-down(md) {
      height: 400px;
    }

    .content-wrapper {
      top: 130px;
      left: 40px;
      width: 600px;
      padding: 15px 20px;
      border: 2px solid #fff;

      @include media-breakpoint-down(md) {
        width: 100%;
        left: 0;
        top: 60px;
        padding: 7.5px 11px;
      }

      .content {
        padding: 30px 40px;
        padding-bottom: 70px;
        background: #fff;
        height: 332px;

        @include media-breakpoint-down(md) {
          height: 242px;
          padding: 20px 20px;
          padding-bottom: 50px;
          text-align: center;
        }

        .title {
          max-height: 80px;
          margin-bottom: 20px;

          @include media-breakpoint-down(md) {
            margin-bottom: 10px;
          }
        }

        .description {
          margin-bottom: 20px;
          color: $grey-text;
          font-size: 18px;
          line-height: 24px;
          max-height: 72px;

          @include media-breakpoint-down(md) {
            max-height: 54px;
            margin-bottom: 10px;
            font-size: 14px;
          }

          @include media-breakpoint-down(sm) {
            line-height: 18px;
          }
        }
      }
    }

    .background-image {
      @include cover-image;
      position: absolute;
      right: 0;
      top: 0;
      max-width: 1190px;
    }
  }

  .owl-dots {
    bottom: 200px;
    left: 100px;

    @include media-breakpoint-down(md) {
      position: absolute;
      bottom: 110px;
      left: 0;
      right: 0;
      text-align: center;
    }

    @include media-breakpoint-down(sm) {
      bottom: 120px;
    }

    @include media-breakpoint-down(xs) {
      bottom: 100px;
    }

    .owl-dot {
      display: inline-block;
      height: 3px;
      width: 25px;
      margin-right: 5px;
      background-color: #dedede;
      @include transition;

      &.active, &:hover {
        background-color: $primary;
      }

      @include media-breakpoint-down(xs) {
        height: 4px;
      }
    }
  }
}

.mod-img-text-background {
  padding-top: 90px;
  padding-bottom: 90px;
  background-color: #F6F6F6;

  h2 {
    text-align: center;
    margin-top: -50px;
  }

  .big-image {
    @include cover-image;
    height: 460px;
  }

  .content-wrapper {
    width: calc(100% + 200px);
    margin-left: -100px;
    padding: 40px 100px;
    border: 2px solid #fff;
    margin-top: -20px;

    @include media-breakpoint-down(md) {
      width: calc(100% + 300px);
      margin-left: -150px;
      padding: 40px 150px;
    }

    .description {
      color: $grey-text;
      font-size: 18px;
      line-height: 24px;

      strong {
        color: $primary;
      }

      @include media-breakpoint-down(md) {
        text-align: center;
      }
    }
  }
}

.mod-text-img-btn-left-right {
  padding-top: 55px;
  padding-bottom: 55px;

  @include media-breakpoint-down(lg) {
    > .container {
      max-width: 100%;
      padding-left: 0;
      padding-right: 0;
    }
  }

  .big-title {
    text-align: center;
  }

  .block {
    height: 200px;
    margin-bottom: 20px;
    background-color: #F6F6F6;

    @include media-breakpoint-down(sm) {
      height: 400px;
    }

    .border {
      position: absolute;
      width: calc(100% - 80px);
      height: 140px;
      top: 30px;
      left: 40px;
      border: 2px solid #fff !important;
      z-index: 2;

      @include media-breakpoint-down(sm) {
        height: calc(100% - 40px);
        width: calc(100% - 40px);
        top: 20px;
        left: 20px;
      }
    }

    .image-wrapper {
      position: absolute;
      width: 50%;
      height: 100%;
      line-height: 200px;
      text-align: center;

      @include media-breakpoint-down(sm) {
        top: 50% !important;
        height: 50%;
        width: 100%;
        left: 0 !important;
        right: 0 !important;
      }

      img {
        @include cover-image;
        position: absolute;
        z-index: 1;
      }

      .btn {
        z-index: 3;

        &:hover {
          background-color: $black;
          border-color: $black;
        }
      }
    }

    .content {
      position: absolute;
      width: 50%;
      top: 50%;
      transform: translateY(-50%);
      padding: 10px 35px;
      padding-left: 75px;

      @include media-breakpoint-down(md) {
        padding-left: 55px;
      }

      @include media-breakpoint-down(sm) {
        top: 0 !important;
        height: 50%;
        width: 100%;
        left: 0 !important;
        right: 0 !important;
        padding: 35px 40px;
        transform: none;
        text-align: center;
      }

      .title {
        text-transform: uppercase;
        font-size: 30px;
        line-height: 40px;

        @include media-breakpoint-down(md) {
          font-size: 24px;
          line-height: 32px;
        }
      }

      .text {
        color: $grey-text;
        font-size: 18px;
        line-height: 24px;

        @include media-breakpoint-down(md) {
          font-size: 16px;
          line-height: 20px;
        }
      }
    }

    &:nth-of-type(odd) {
      .image-wrapper {
        right: 0;

        img {
          right: 0;
        }
      }

      .content {
        left: 0;
      }
    }

    &:nth-of-type(even) {
      .image-wrapper {
        left: 0;

        img {
          left: 0;
        }
      }

      .content {
        right: 0;
        padding-right: 55px;
      }
    }
  }
}

.mod-articles {
  padding-top: 30px;
  padding-bottom: 15px;

  @include media-breakpoint-down(md) {
    padding-bottom: 0px;
  }


  .big-title {
    text-align: center;
  }

  .article {
    @include media-breakpoint-down(md) {
        margin-bottom: 40px;
    }

    .image-wrapper {
      position: relative;
      height: 230px;

      img {
        @include cover-image;
      }

      .border {
        position: absolute;
        bottom: 12px;
        width: 100%;
        border: 1px solid #fff !important;
      }
    }

    .title {
      margin-top: 17px;
      margin-bottom: 12px;
      height: 42px;
      overflow: hidden;
    }

    .description {
      margin-bottom: 15px;
      font-size: 18px;
      line-height: 24px;
      color: $grey-text;
      height: 72px;
      overflow: hidden;
    }

    @include media-breakpoint-down(sm) {
      .btn {
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }
}

.intro-collection {
  position: relative;
  height: 460px;
  background: #F6F6F6;

  .row {
    height: 460px;
  }

  @include media-breakpoint-down(md) {
    height: 500px;

    .row {
      height: 500px;
    }
  }

  .content-wrapper {
    width: 100%;
    padding: 15px 20px;
    border: 2px solid #fff;

    @include media-breakpoint-down(md) {
      padding: 7.5px 11px;
    }

    .content {
      padding: 30px 40px;
      padding-bottom: 70px;
      background: #fff;
      text-align: center;

      @include media-breakpoint-down(md) {
        padding: 20px 20px;
        padding-bottom: 50px;
      }

      .title {
        margin-bottom: 20px;

        @include media-breakpoint-down(md) {
          margin-bottom: 10px;
        }
      }

      .description {
        margin-bottom: 20px;
        color: $grey-text;
        font-size: 18px;
        line-height: 24px;

        @include media-breakpoint-down(md) {
          margin-bottom: 10px;
          font-size: 14px;
        }
      }
    }
  }

  .background-image {
    @include cover-image;
    position: absolute;
    right: 0;
    top: 0;
    max-width: 1190px;
  }
}

.intro-page {
  position: relative;

  .top-banner {
    position: relative;
    height: 267px;
    background: #F6F6F6;

    .background-image {
      @include cover-image;
      position: absolute;
      right: 0;
      top: 0;
      width: 56%;

      @include media-breakpoint-down(md) {
        width: 100%;
      }
    }
  }

  .content-wrapper {
    width: 100%;
    padding: 17px 27px;
    border: 2px solid #fff;
    margin-top: -153px;

    .content {
      padding: 35px 0px;
      padding-bottom: 0;
      background: #fff;
      text-align: center;

      @include media-breakpoint-down(md) {
        padding: 8px;
        padding-bottom: 0;
      }

      .title {
        margin-bottom: 12px;
        color: #000;
      }

      .description {
        color: $grey-text;
        font-size: 18px;
        line-height: 24px;

        @include media-breakpoint-down(md) {
          font-size: 14px;
        }

        img {
          height: auto;
          max-width: 100%;
        }
      }
    }
  }

  .background-image {
    @include cover-image;
    position: absolute;
    right: 0;
    top: 0;
    max-width: 1190px;
  }
}

.mod-wysiwyg {
  padding-top: 25px;
  padding-bottom: 25px;
  color: $grey-text;
  font-size: 18px;
  line-height: 24px;

  strong {
    color: $primary;
  }

  @include media-breakpoint-down(md) {
    text-align: center;
  }

  ul {
    margin-left: 0;
    padding-left: 0;

    li {
      margin-bottom: 10px;
      padding-left: 20px;
      list-style-type: none;
      background: url('#{$svg-dir}icon-arrow-right-blue.svg') no-repeat 0 8.45px;
    }
  }
}

.mod-video {
  padding-top: 25px;
  padding-bottom: 25px;

  .title {
    margin-bottom: 10px;
    text-align: center;
    color: #000;
  }

  iframe, video {
    width: 100%;
    height: 430px;
  }
}