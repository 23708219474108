@import '../base/variables';
@import '../../../../../../../node_modules/bootstrap/scss/mixins/breakpoints';

/*------------------------------------*\
		Footer
\*------------------------------------*/

footer#footer {
  position: relative;
  width: 100%;
  margin-top: 90px;

  @include media-breakpoint-down(sm) {
    text-align: center;
  }

  #footer-top {
    padding: 35px 0;
    background-color: $light-grey;

    #logo-azurlign {
      display: inline-block;
      width: 236px;
      max-width: 100%;
      text-decoration: none;

      @include media-breakpoint-down(sm) {
        margin-bottom: 35px;
        width: 165px;
      }

      img {
        width: 100%;
      }

      .baseline {
        margin-top: -15px;
        color: $grey-text;
        font-size: 11px;

        @include media-breakpoint-down(md) {
          text-align: left;
          margin-top: -13px;
          font-size: 9px;
        }
      }
    }

    .list-header {
      margin-bottom: 10px;
      font-weight: 500;
      text-transform: uppercase;
      color: $grey-text;
    }

    ul {
      padding-left: 0;
      margin-left: 0;
      list-style: none;

      @include media-breakpoint-down(sm) {
        margin-bottom: 50px;
      }

      li {
        margin-bottom: 10px;

        a {
          color: rgba($grey-text, .64);
          text-decoration: none;

          &:hover, &:active, &:focus {
            color: $grey-text;
          }
        }
      }
    }

    .menu-footer-1 {
      ul li:first-child {
        font-weight: 500;
        text-transform: uppercase;

        a {
          color: $grey-text;
        }
      }
    }

    .menu-footer-2 {
      ul li {
        font-weight: 500;
        text-transform: uppercase;

        a {
          color: $grey-text;
        }
      }
    }
  }

  #footer-bottom {
    padding: 15px 0;
    background-color: #fff;
    text-align: center;
    font-size: 14px;
    color: $grey-text;

    a {
      color: $grey-text;
    }

    img {
      height: 15px;
    }
  }
}