@import '../base/variables';
@import '../../../../../../../node_modules/bootstrap/scss/mixins/breakpoints';

/*------------------------------------*\
		Buttons
\*------------------------------------*/

.btn {
  padding: 12px 45px;
  border-radius: 0;
  line-height: 1;
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;

  &:focus {
    outline: 0;
    box-shadow: none;
  }

  &:disabled {
    opacity: .5;
  }

  &.btn-blue {
    background-color: $blue;
    color: #fff;

    &:hover, &:active {
      background-color: $black;
    }
  }

  &.btn-primary {
    color: #fff;

    &:hover, &:active {
      background-color: $primary-dark;
    }
  }

  &.btn-outline-primary {
    &:hover, &:active, &:focus {
      color: #fff;
    }
  }

  span {
    vertical-align: middle;
  }

  img {
    display: inline-block;
    vertical-align: middle;
    height: 18px;
    margin-right: 18px;
  }
}