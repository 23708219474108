//		Réglages globaux

@import '../base/variables';
@import '../tools/mixins';
@import '../../../../../../../node_modules/bootstrap/scss/mixins/breakpoints';

// Add font

@include font-face('Segoe UI', $font-dir + '/Segoe_UI/segoeui', normal, normal, (eot, ttf));
@include font-face('Segoe UI', $font-dir + '/Segoe_UI/segoeuib', bold, normal, (eot, ttf));
@include font-face('Segoe UI', $font-dir + '/Segoe_UI/segoeuisb', 500, normal, (eot, ttf));
@include font-face('Segoe UI', $font-dir + '/Segoe_UI/segoeuisbi', bold, italic, (eot, ttf));
@include font-face('Segoe UI', $font-dir + '/Segoe_UI/segoeuii', normal, italic, (eot, ttf));

*,
*::after,
*::before {
  outline: none;
  position: relative;
  -webkit-tap-highlight-color: none;
}

// Better font rendering for mac
.is-mac,
.is-mac input,
.is-mac button,
.is-mac textarea {
  @include antialiased;
}


main {
  overflow-x: hidden;
  margin-top: 130px;

  @include media-breakpoint-down(md) {
    margin-top: 80px;
  }
}

body {
  font-family: $font-family;
  font-size: $font-size;
  line-height: $line-height;
  overflow-x: hidden;
}

.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
  margin-bottom: 0;
}

h1 {
  font-size: 40px;
  font-weight: bold;
  line-height: 44px;
  color: $black;
  //color: $primary;

  @include media-breakpoint-down(sm) {
    font-size: 26px;
    line-height: 32px;
  }
}

h2 {
  margin-bottom: 30px;
  font-size: 34px;
  font-weight: 500;
  color: $black;

  @include media-breakpoint-down(sm) {
    font-size: 20px;
    line-height: 27px;
  }
}

h3 {
  font-size: 30px;
  font-weight: 500;
  line-height: 40px;
  color: $black;

  @include media-breakpoint-down(sm) {
    font-size: 18px;
    line-height: 24px;
  }
}

h4 {
  font-size: 20px;
  font-weight: 500;
  line-height: 30px;
  color: $primary;

  @include media-breakpoint-down(sm) {
    font-size: 16px;
    line-height: 20px;
  }
}

h5 {
  font-size: 18px;
  font-weight: 500;
  color: $black;
}


a {
  color: $primary;
  text-decoration: none;
  @include transition;

  svg {
    @include transition;

    path {
      @include transition;
    }
  }

  &:hover {
    color: $purple;

    svg {
      path {
        fill: $purple;
      }
    }
  }

}

p {
  margin-bottom: 0;
}


ul,
ol {
  margin-left: 1em;
  padding-left: 1em;
}

b,
strong {
  font-weight: bold;
}

.aligncenter {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

#breadcrumbs {
  margin-top: 13px;
  margin-bottom: 13px;
  font-size: 18px;
  line-height: 24px;
  color: #9C9C9C;

  @include media-breakpoint-down(sm) {
    display: none;
  }

  a {
    color: #9C9C9C;
  }

  .breadcrumb_last {
    color: $grey-text;
  }
}

body.page-template-contact {
  h1 {
    margin-bottom: 10px;
    color: #000;
    text-align: center;
  }

  .intro-description {
    margin-bottom: 20px;
    color: $grey-text;
    text-align: center;
  }
}

body.page-template-page-simple {
  main {
    color: $grey-text;

    h1 {
      margin-top: 15px;
      margin-bottom: 20px;
    }

    ul {
      margin-left: 0;
      padding-left: 0;

      li {
        margin-bottom: 10px;
        padding-left: 20px;
        list-style-type: none;
        background: url('#{$svg-dir}icon-arrow-right-blue.svg') no-repeat 0 8.45px;
      }
    }
  }
}

body.blog {
  main {
    color: $grey-text;

    h1 {
      margin-top: 33px;
    }
  }

  .mod-articles {
    .article {
      margin-bottom: 40px;
    }
  }
}

#articles-pagination {
  text-align: right;
  color: #ABABAB;
  font-size: 16px;

  @include media-breakpoint-down(sm) {
    text-align: center;
  }

  ul {
    display: inline-block;
    margin-bottom: 0;
    list-style: none;
    margin-left: 0;
    padding-left: 0;
    font-weight: 500;

    li {
      display: inline-block;
      margin: 0 3px;

      .current {
        color: $grey-text;
      }

      a {
        color: #ABABAB;

        &:hover, &:active, &:focus {
          color: $grey-text;
        }
      }
    }
  }

  > .prev, > .next {
    display: inline-block;
    opacity: .48;

    &:hover, &:active, &:focus {
      opacity: 1;
    }

    img {
      height: 14px;
    }
  }

  > .prev {
    margin-right: 4px;
  }

  > .next {
    margin-left: 4px;
  }
}

.cli-plugin-button {
  padding: 12px 45px !important;
  text-decoration: none !important;
}

@include media-breakpoint-down(sm) {
  .row {
    margin-right: -5px;
    margin-left: -5px;

    > .col, >[class*=col-] {
      padding-left: 5px;
      padding-right: 5px;
    }
  }
}

.fancybox-navigation {
  height: 100%;

  .fancybox-button {
    &.disabled {
      display: none !important;
    }
  }
}

.grecaptcha-badge {
  display: none;
}