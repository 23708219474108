@import '../base/variables';
@import '../../../../../../../node_modules/bootstrap/scss/mixins/breakpoints';

/*------------------------------------*\
		Forms
\*------------------------------------*/

form {
  .wpcf7-list-item {
    margin-left: 0 !important;
  }

  label {
    display: block;
    margin-top: 23px;
    margin-bottom: 10px;
    text-align: left;
    color: $grey-text;
    text-transform: uppercase;
  }

  input:not([type="submit"]), textarea {
    width: 100%;
    height: 45px;
    padding: 0 20px;
    border: 1px solid #707070;
    line-height: 43px;

    &::placeholder {
      color: rgba($grey-text, .4);
      font-style: italic;
      font-size: 14px;
    }

    &.wpcf7-not-valid {
      border-color: red;
    }

    &.wpcf7-valid {
      border-color: #00B819;
    }
  }

  input[type="submit"] {
    margin-top: 25px;
  }

  textarea {
    padding: 20px;
    line-height: 24px;
    height: auto;
  }

  select {
    width: 100%;
    height: 45px;
    padding: 0 20px;
    padding-right: 40px;
    border: 1px solid #707070;
    border-radius: 0;
    background: #fff;
    line-height: 43px;
    appearance: none;
    background-image: url('#{$svg-dir}icon-chevron-down-black.jpg');
    background-position: right 20px center;
    background-size: 11.4px 7.1px;
    background-repeat: no-repeat;

    &::placeholder {
      color: rgba($grey-text, .4);
      font-style: italic;
      font-size: 14px;
    }

    &::-ms-expand {
      display: none;
    }

    &.wpcf7-not-valid {
      border-color: red;
    }

    &.wpcf7-valid {
      border-color: #00B819;
    }
  }

  .wpcf7-acceptance {
    color: $grey-text;

    label {
      position: relative;
      text-transform: none;
    }

    .wpcf7-list-item-label {
      display: block;
      width: 100%;
      padding-left: 33px;

      &:before {
        display: inline-block;
        position: absolute;
        top: 3px;
        left: 0;
        content: "";
        width: 24px;
        height: 24px;
        vertical-align: top;
        border: 1px solid #707070;
      }
    }

    input {
      display: none;

      &:checked + .wpcf7-list-item-label:before {
        background-image: url('#{$svg-dir}icon-check.svg');
        background-size: 12.6px 8.5px;
        background-position: center;
        background-repeat: no-repeat;
      }
    }

    a {
      color: $grey-text;
      text-decoration: underline;
    }

    .new-line {
      display: block;
      margin-top: 10px;
    }
  }

  .wpcf7-not-valid-tip {
    display: none !important;
  }

  .wpcf7-response-output {
    &.wpcf7-validation-errors, &.wpcf7-acceptance-missing {
      border-color: red;
      color: red;
    }
  }
}