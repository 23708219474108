@import '../base/variables';
@import '../../../../../../../node_modules/bootstrap/scss/mixins/breakpoints';

/*------------------------------------*\
		Header
\*------------------------------------*/

header#header {
  position: fixed;
  height: 130px;
  width: 100%;
  top: 0;
  z-index: 1000;
  background: #fff;
  border-bottom: 1px solid #F6F6F6;

  @include media-breakpoint-down(md) {
    height: 80px;
  }

  > nav.navbar {
    height: 40px;
    margin-bottom: 15px;
    background: $light-grey;

    @include media-breakpoint-down(md) {
      height: 80px;
      margin-bottom: 0;
      padding: 0 15px;
      background: #fff;
    }

    .navbar-toggler {
      position: fixed;
      top: 10px;
      right: 10px;
      padding: 16px 10px;
      background-color: $primary;
      border: none;
      border-radius: 0;

      .navbar-toggler-icon {
        display: block;
        height: 3px;
        background-color: #fff;

        &:not(:last-child) {
          margin-bottom: 4.5px;
        }
      }
    }

    .navbar-nav {
      width: 100%;
      margin-left: 0;
    }
  }

  #logo-azurlign {
    display: inline-block;
    width: 236px;
    max-width: 100%;
    text-decoration: none;

    @include media-breakpoint-down(md) {
      position: fixed;
      top: 15px;
      left: 10px;
      width: 165px;
    }

    img {
      width: 100%;
    }

    .baseline {
      margin-top: -15px;
      color: $grey-text;
      font-size: 11px;

      @include media-breakpoint-down(md) {
        margin-top: -13px;
        font-size: 9px;
      }
    }
  }

  .btn {
    padding: 20px;
    font-size: 16px;

    img.active {
      display: none;
    }

    &.active {
      img {
        display: none;

        &.active {
          display: inline-block;
        }
      }
    }
  }

  #link-collections {
    margin-right: 16px;
    min-width: 230px;
    text-align: right;
  }

  #link-collections, #link-download-catalogue {
    @include media-breakpoint-down(md) {
      display: none;
    }
  }

  #link-collections-mobile {
    width: 100%;
    padding: 10px;

    img {
      height: 7px;
    }
  }

  .mod-product-categories {
    position: fixed;
    top: 116px;
    width: 100%;
    display: none;
    padding-top: 45px;
    padding-bottom: 45px;
    background: #fff;

    @include media-breakpoint-down(md) {
      display: none !important;
    }

    .owl-carousel {
      @media (max-width: 1550px) {
        .owl-nav {
          .owl-prev {
            right: -50px;
            left: auto;
          }

          .owl-next {
            left: -50px;
            right: auto;
          }
        }
      }

      @include media-breakpoint-down(lg) {
        .owl-nav {
          .owl-prev {
            right: -45px;
            left: auto;
          }

          .owl-next {
            left: -45px;
            right: auto;
          }
        }
      }
    }
  }
}