@import '../base/variables';
@import '../../../../../../../node_modules/bootstrap/scss/mixins/breakpoints';

/*------------------------------------*\
						Main menu
\*------------------------------------*/

#main-menu {
  @include media-breakpoint-down(md) {
    position: fixed;
    top: 0px;
    left: 0;
    z-index: 1000;
    background: #fff;
    width: 100%;
  }

  .header-mobile {
    height: 60px;
    color: $primary;
    text-align: center;
    line-height: 60px;
    font-size: 18px;
    font-weight: bold;
    text-transform: uppercase;

    img {
      position: absolute;
      height: 18px;
      top: 20px;
      right: 20px;
      cursor: pointer;
    }
  }

  .navbar-nav {
    left: 0;
    justify-content: space-between;

    .nav-item {
      flex-grow: initial;
      flex-basis: auto;

      @include media-breakpoint-up(lg) {
        &:nth-child(2) {
          text-align: left;
        }

        &:nth-child(6) {
          text-align: right;
        }
      }

      @include media-breakpoint-down(md) {
        margin-bottom: 20px;
      }

      a {
        text-transform: uppercase;

        &:not(.btn) {
          font-size: 14px;
          color: $grey-text;
        }
      }

      ul {
        display: none;
        margin-left: 0;
        margin-top: 20px;
        padding-left: 0;
        list-style: none;
      }
    }

    > .nav-item:first-child {
      border-bottom: 1px solid rgba($grey-text, .1);
    }
  }
}